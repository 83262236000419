export function FontPreload() {
  return (
    <>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/Afek1.5AAA-Bold.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/Afek1.5AAA-Regular.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/Afek1.5AAA-Medium.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/raanancondensedbold-web.woff2"
        rel="preload"
        type="font/woff2"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/BalooBhaijaan2-Bold.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/BalooBhaijaan2-Regular.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/BalooBhaijaan2-Medium.ttf"
        rel="preload"
        type="font/ttf"
      ></link>
    </>
  )
}
