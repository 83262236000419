import type { URLOpenListenerEvent } from '@capacitor/app'
import { App } from '@capacitor/app'
import type { MetaFunction } from '@remix-run/node'
import type { ClientLoaderFunctionArgs } from '@remix-run/react'
import {
  Await,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { QueryClientProvider } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import i18next from 'i18next'
import { isEmpty } from 'lodash-es'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalPendingState } from 'remix-utils/use-global-navigation-state'
import { Dialog } from './components/atoms/dialog/dialog'
import { Toast } from './components/atoms/toast/toast'
import { Tooltip } from './components/atoms/tooltip/tooltip'
import { NoalIcon } from './components/common/noal-icon'
import { FontPreload } from './components/font-preload'
import type { QNACollection } from './generated/api'
import { KehilaKeyEnum } from './generated/api'
import { analytics, formSubmitted } from './lib/analytics'
import { api } from './lib/api-client'
import { messagesStore } from './lib/messages'
import { CacheKeys, queryClient } from './lib/query-client'
import './styles/app.css'
import './styles/fonts.css'
import 'nprogress/nprogress.css'

export const meta: MetaFunction = () => {
  const metaTags = [
    { title: 'אתר ההדרכה ״לעולם אדם״' },
    {
      property: 'og:site_name',
      content: '{{ HADRACHA_TITLE }}',
    },
    {
      property: 'og:title',
      content: '{{ HADRACHA_TITLE }}',
    },
    {
      name: 'description',
      content: '{{ HADRACHA_DESCRIPTION }}',
    },
    {
      name: 'og:description',
      content: '{{ HADRACHA_DESCRIPTION }}',
    },
    {
      name: 'og:image',
      content: '{{ HADRACHA_IMAGE }}',
    },
  ]

  return metaTags
}

export async function clientLoader({ request }: ClientLoaderFunctionArgs) {
  const url = new URL(request.url)
  const qna = url.searchParams.get('qna')
  let qnaCollection: Promise<QNACollection> | null = null
  const { toasts } = messagesStore.getState()

  if (qna) {
    qnaCollection = queryClient.fetchQuery({
      queryKey: [CacheKeys.Qna, qna],
      queryFn: () => api.qnaGroup.qnaGroupRetrieve(Number(qna)),
    })
  }

  return defer({ qnaCollection, toasts })
}

function Root() {
  const [toastIsOpen, setToastIsOpen] = React.useState(false)
  const timerRef = React.useRef(0)
  const loaderData = useLoaderData<typeof clientLoader>()
  const pendingState = useGlobalPendingState()
  const location = useLocation()
  const { i18n } = useTranslation()

  useMockKehila()
  useCapacitor()

  React.useEffect(() => {
    const timer = timerRef.current
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    if (!isEmpty(loaderData.toasts)) {
      setToastIsOpen(false)

      window.clearTimeout(timerRef.current)

      timerRef.current = window.setTimeout(() => {
        setToastIsOpen(true)
      }, 100)
    }
  }, [loaderData.toasts])

  React.useEffect(() => {
    if (pendingState === 'pending') NProgress.start()

    if (pendingState === 'idle') NProgress.done()
  }, [pendingState])

  React.useEffect(() => {
    analytics.page()
  }, [location.pathname])

  React.useEffect(() => {
    document.documentElement.lang = i18n.language

    switch (i18n.language) {
      case 'he':
        document.body.style.fontFamily = 'Afek'
        break
      case 'ar':
        document.body.style.fontFamily = 'BalooBhaijaan2'
        break
      default:
        throw new Error('Unsupported language')
    }
  }, [i18n.language])

  return (
    <motion.div
      className="h-full"
      layoutScroll
      onSubmit={(e) => formSubmitted({ target: e.target as HTMLFormElement })}
    >
      <Toast.Provider duration={3000}>
        <Toast.Viewport />
        {loaderData.toasts.map((message, i) => (
          <Toast intent={message?.type} key={i} onOpenChange={setToastIsOpen} open={toastIsOpen}>
            <Toast.Title>{message?.title}</Toast.Title>
            <Toast.Description>{message?.description}</Toast.Description>
          </Toast>
        ))}
        <Tooltip.Provider>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </Tooltip.Provider>
      </Toast.Provider>
      <React.Suspense fallback={<NoalIcon className="text-blue-500" />}>
        <Await resolve={loaderData.qnaCollection}>
          {(resolved) => resolved?.questions && <QnaDialog questions={resolved.questions} />}
        </Await>
      </React.Suspense>
    </motion.div>
  )
}

export function HydrateFallback() {
  return (
    <div className="flex h-full w-full items-center justify-center bg-grey-100">
      <img
        alt="splash screen"
        className="h-full w-full object-cover sm:hidden"
        src="/splash.webp"
      />
    </div>
  )
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html className="h-full" dir="rtl">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width,initial-scale=1,viewport-fit=cover" name="viewport" />
        <Meta />
        <Links />
        <FontPreload />
        <script src="/env.js" />
      </head>
      <body className="h-full antialiased">
        {children}
        {import.meta.env.PROD && (
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3462456,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
            suppressHydrationWarning
          />
        )}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default Sentry.withSentry(Root)

/**
 * this is the mechanism that help devlopers and playwright
 * to simulate different kehilot.
 */
function useMockKehila() {
  const revalidate = useRevalidator()
  React.useEffect(() => {
    if (import.meta.env.DEV) {
      // @ts-expect-error
      window.mockKehila = (kehilaKey: KehilaKeyEnum) => {
        window.__NOAL_ENV__ = { kehila: kehilaKey }
        if ([KehilaKeyEnum.KAravit, KehilaKeyEnum.KDruzit].includes(kehilaKey)) {
          i18next.changeLanguage('ar')
        } else {
          i18next.changeLanguage('he')
        }
        revalidate.revalidate()
      }
    }
  }, [revalidate])
}

function useCapacitor() {
  const navigate = useNavigate()

  useEffect(() => {
    window.handleQuickAction = (type: 'cycle' | 'favorites' | 'profile') => {
      if (type === 'cycle') {
        navigate('/')
      }
      if (type === 'favorites') {
        navigate('/admin/favorites')
      }
      if (type === 'profile') {
        navigate('/admin/profile')
      }
    }
  }, [])

  const handleAppUrlOpen = React.useCallback(async () => {
    return await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.noal.org.il').pop()

      if (slug) {
        navigate(slug)
      }
    })
  }, [navigate])

  const handleBackButton = React.useCallback(() => {
    return App.addListener('backButton', () => {
      if (location.pathname === '/') {
        App.exitApp()
      } else {
        navigate(-1)
      }
    })
  }, [navigate])

  React.useEffect(() => {
    handleBackButton()
    handleAppUrlOpen()
    return () => {
      App.removeAllListeners()
    }
  })
}

function QnaDialog({ questions }: { questions: QNACollection['questions'] }) {
  const { t } = useTranslation('root')
  const [, setSearchParams] = useSearchParams()

  return (
    <Dialog
      defaultOpen
      modal
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setSearchParams({})
        }
      }}
    >
      <Dialog.Content className="max-h-[500px] w-full max-w-2xl px-0 py-3 md:max-h-96">
        <h1 className="mb-4 px-4 text-2xl">{t('שאלות ותשובות')}</h1>
        <div className="mb-8 max-h-96 overflow-auto rounded-lg bg-grey-50 p-4">
          {questions?.map((item) => (
            <div className="p-2" key={item.id}>
              <p className="font-bold">{item.question}</p>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>
        <Link
          className="absolute bottom-0 left-2 p-4 text-left text-sm font-semibold text-blue-500"
          to={{ search: '' }}
        >
          {t('סגירה')}
        </Link>
      </Dialog.Content>
    </Dialog>
  )
}
